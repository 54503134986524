<template>
  <div class="footer-container">
    <p>
      Copyright ©2023 厦门车易行科技有限公司</p><p @click="jumpICP" class="icp"><svg-icon icon-class="icp" class-name="icon"></svg-icon>闽ICP备2023011957号</p>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  components: {},
  data () {
    return {}
  },
  methods: {
    jumpICP () {
      window.location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";
.footer-container {
  position: fixed;
  bottom: 0;
  left: 50%;
  height: 80px;
  width: 400px;
  transform: translateX(-50%);
  border-radius: 15px;
  background-color: $theme-color;
  box-sizing: border-box;
  display: block;
  font-family: SourceHanSans-Regular;
  font-size: 20px;
  line-height: 1.33333;
  padding: 10px;
  box-shadow: 0 0 15px $theme-color;
  z-index: 600;
  p {
    color: $t-font;
    line-height: 30px;
  }
  .icon {
    color: $t-font;
  }
  .icp:hover {
    color: orange;
    cursor: pointer;
  }

}

</style>
