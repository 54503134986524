import axios from "axios";
import store from "../store";

// 创建axios对象
console.log(process.env);
axios.defaults.baseURL = process.env.VUE_APP_API_ROOT;

const Server = axios.create({
  timeout: 30000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
// 前置拦截器
Server.interceptors.request.use(
  (config) => {
    if (store.state.auth_token) {
      // eslint-disable-next-line no-undef
      config.headers.Authorization = "Token " + store.state.auth_token;
      config.headers.company = store.state.user_company;
      // config.headers.company =  2
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default Server;
