// 判断手机系统
export const getNavigatorType = () => {
  let temp = ''
  const u = navigator.userAgent
  if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
    temp = 'Android'
  } else {
    temp = 'ios'
  }
  return temp
}
