import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/icons'

import { Form, FormItem, Button, Input, Container, Aside, Menu, Submenu, MenuItemGroup, MenuItem, Header, Dropdown, Table, TableColumn, RadioGroup, RadioButton, Row, Col, Main, DropdownMenu, DropdownItem, Pagination, Message, DatePicker, Select, Option, Switch, Popover, Carousel, CarouselItem,MessageBox,Tabs,TabPane,Checkbox,CheckboxGroup, Radio, Tree, Dialog, Progress, Upload } from 'element-ui'
import server from './utils/request.js' // icon

if (process.env.NODE_ENV === 'development') {
  require('element-ui/lib/theme-chalk/index.css')
}

// import '@/utils/rem'
if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
  Vue.prototype.$pc = false
} else {
  Vue.prototype.$pc = true
}


Vue.use(Upload)
Vue.use(Progress)
Vue.use(Dialog)
Vue.use(Tree)
Vue.use(Radio)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)
Vue.use(Switch)
Vue.use(Option)
Vue.use(Select)
Vue.use(DatePicker)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Button)
Vue.use(Input)
Vue.use(Container)
Vue.use(Aside)
Vue.use(Menu)
Vue.use(Submenu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Header)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Row)
Vue.use(Col)
Vue.use(Main)
Vue.use(Pagination)
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$server = server
Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
Vue.prototype.$bus = new Vue()


Vue.directive('longpress', {
  bind: function (el, binding, vnode) {
    let pressTimer = null
    el.addEventListener('mousedown', (e) => {
      pressTimer = setTimeout(() => {
        binding.value()
      }, 1000)
    })
    el.addEventListener('mouseup', (e) => {
      clearTimeout(pressTimer)
    })
  }
})


new Vue({
  beforeCreate () {
    Vue.prototype.$bus = this
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
