<template>
  <div class="home">
    <div class="container">
      <div class="line">
        <router-view/>
      </div>
    </div>
      <img class="wallpaper" :src="wp"  />
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  data () {
    return {
      wp: require('@/assets/wallpaper.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  color: #000;
  position: relative;
  .wallpaper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    object-fit: cover;
    height: 100%;
    width: 100%;

  }
  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .line {
      display: flex;
    }
  }
}

</style>

<style >
.el-container {
  height: 100%;

}
.el-main {
  height: 100%;
}
</style>
