<template>
  <div class="header-container">
    <div class="logo"><img :src="logo"></div>
  </div>
</template>

<script>
export default {
  name: 'AppHeader',
  components: {},
  data () {
    return {
      logo : require('@/assets/logo.png')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/colors.scss";

.header-container {
  align-items: center;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  background-color: $theme-color;
  box-shadow: 0 0 15px $theme-color;
  display: flex;
  height: 72px;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 600;
  .logo {
    p {
      font-size: 50px;
      font-weight: 800;
    }
    img {
      height: 60px;

    }
  }
}

.move-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  background-color: #fff;
  border-bottom: 1px solid #f4f4f4;
  ul {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      a {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        letter-spacing: 2px;
        padding: 0 10px;
        height: 100%;
        line-height: 100%;
      }
      &:hover {
        background-color: $deepblue;
        a {
          color: #fff;
        }
      }
    }
  }
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: $blue;
  width: 100%;
  height: 100%;
}
</style>

