import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import Layout from '@/components/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    redirect: 'index',
    component: Layout,
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import(/* webpackChunkName: "LayoutChunk" */ '@/views/NotificationHome')
      }
    ]
  },
  {
    path: '/panel',
    name: 'home',
    component: HomeView,
    redirect: 'login',
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "LoginChunk" */ '@/components/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "RegistreChunk" */ '@/components/Register.vue')
      }
    ]
  },
  {
    path: '/main',
    name: 'main',
    // route level code-splitting
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "MainLayoutChunk" */ '../views/MainView.vue'),
    children: [
      {
        path: 'task',
        name: 'task',
        component: () => import(/* webpackChunkName: "TaskChunk" */ '@/components/TaskView.vue')
      },
      {
        path: 'analysis',
        name: 'analysis',
        component: () => import(/* webpackChunkName: "AnalysisChunk" */ '@/components/AnalysisView.vue')
      },
      {
        path: 'team',
        name: 'team',
        component: () => import(/* webpackChunkName: "TeamChunk" */ '@/components/TeamView.vue'),
        children: [
          {
            path: 'members',
            name: 'members',
            component: () => import(/* webpackChunkName: "MembersChunk" */ '@/components/MembersView/index.vue')
          },
          {
            path: 'group',
            name: 'group',
            component: () => import(/* webpackChunkName: "GroupChunk" */ '@/components/GroupView/index.vue')
          },
          {
            path: 'company',
            name: 'company',
            component: () => import(/* webpackChunkName: "CompanyChunk" */ '@/components/CompanyView/index.vue')
          },
        ],
      },
      {
        path: 'valuations',
        name: 'valuations',
        component: () => import(/* webpackChunkName: "ValuationsChunk" */ '@/components/ValuationsView.vue')
      },
      {
        path: 'client',
        name: 'client',
        component: () => import(/* webpackChunkName: "ClientChunk" */ '@/components/ClientView.vue'),
        children: [
          {
            path: 'miniProgram',
            name: 'miniProgramUser',
            component: () => import(/* webpackChunkName: "MiniProgramUserChunk" */ '@/components/MiniProgramUser/index.vue')
          },
          {
            path: 'qunKong',
            name: 'qunKongUser',
            component: () => import(/* webpackChunkName: "QunKongUserChunk" */ '@/components/QunKongUser/index.vue')
          },
        ],
      },
      {
        path: 'chat',
        name: 'chat',
        component: () => import(/* webpackChunkName: "ChatChunk" */ '@/components/ChatView.vue')
      },
      {
        path: 'announcements',
        name: 'announcements',
        component: () => import(/* webpackChunkName: "AnnouncementsChunk" */ '@/components/AnnouncementsView.vue'),
        children: [
          {
            path: 'list',
            name: 'announcements-list',
            component: () => import(/* webpackChunkName: "AnnouncementsListChunk" */ '@/components/AnnouncementsList')
          },
          {
            path: 'detail/:id?',
            props: (route) => ({
              id: route.params.id || 'new'
            }),
            name: 'announcements-detail',
            component: () => import(/* webpackChunkName: "AnnouncementsDetailChunk" */ '@/components/AnnouncementsDetail/index.vue')
          }
        ]
      },
      {
        path: 'popups',
        name: 'popups',
        // redirect: 'popups/list',
        component: () => import(/* webpackChunkName: "PopupsLayoutChunk" */ '@/components/PopupsView.vue'),
        children: [
          {
            path: 'list',
            name: 'popups-list',
            component: () => import(/* webpackChunkName: "PopupsListChunk" */ '@/components/PopupsList')
          },
          {
            path: 'detail/:id?',
            props: (route) => ({
              id: route.params.id || 'new'
            }),
            name: 'popups-detail',
            component: () => import(/* webpackChunkName: "PopupsDetailChunk" */ '@/components/PopupsDetail/index.vue')
          }
        ]
      }
    ]

  },
  {
    path: '/placard/detail/:id',
    name: 'placard',
    props: true,
    component: () => import(/* webpackChunkName: "PlacardChunk" */ '@/views/PlacardView.vue')
  },
  {
    path: '/index_p',
    name: 'PhoneHome',
    props: true,
    component: () => import(/* webpackChunkName: "PhoneHomeChunk" */ '@/views/PhoneHome')
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // next()
  if (to.path === '/login' || to.path === '/register' || to.path === '/' || to.path === '/index' || to.path.startsWith('/placard/detail/') || to.path.endsWith('_p')) {
    next()
  } else {
    const token = store.state.auth_token

    if (token === null || token === '') {
      next('/login')
    } else {
      next()
    }
  }
})

export default router
