<template>
  <div>
    <div class="app-container">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppMain',
  components: {},
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  margin: 0;
}
</style>
