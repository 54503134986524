import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      aside_width: 60,
      auth_token: null,
      isFilterOpen: null,
      filterKey: null,
      filterValue: "",
      isFilterDisplay: false,
      isRenew: false,
      windowWidth: 0,
      windowHeight: 0,
      floatWindowFlag: false,
      isUpBtnOn: false,
      isDownBtnOn: false,
      isRightBtnOn: false,
      isCollectBtnOn: false,
      socket: null,
      socketStatus: "未建立连接",
      msgList: [],
      playSound: true,
      msgCount: 0,
      msgIndex: null, // 用来定位虚拟列表的位置
      popSound: null, //
      clientTableData: [],
      analysisOption: {},
      username: null,
      userid: null,
      user_company: -1,
      usergroup: {
        id: "",
        groupname: "",
        group_owner_name: "",
        menu: [],
      },
      defaultPage: "/main/task/",
      freeMembers: [],
      ownerGroups: [],
      selectGroup: {
        groupname: null,
      },
      equipments: [],
    };
  },
  getters: {},
  mutations: {
    setFreeMembersInstance(state, obj) {
      state.freeMembers[obj.rowIndex] = obj.value;
      console.log(state.freeMembers);
    },
    setState(state, obj) {
      state[obj.key] = obj.value;
    },
    msgList_save(state) {
      window.localStorage.setItem("msgList", JSON.stringify(state.msgList));
    },
    msgList_init(state) {
      let msgList = null;
      const msgListStr = window.localStorage.getItem("msgList");
      if (msgListStr === null || msgListStr === undefined) {
        msgList = [];
      } else {
        msgList = JSON.parse(msgListStr);
      }
      state.msgList = msgList;
      state.msgCount = msgList.length;
    },
    msgList_search_update(state, data) {
      state.msgList.forEach((ele) => {
        if (ele.user_id === data.user_id) {
          Object.assign(ele, data);
        }
      });
    },
    msgList_update(state, data) {
      const index = state.msgList.findIndex((ele) => {
        return data.message === ele.message && data.time === ele.time;
      });
      if (index !== -1) {
        const oldIndex = state.msgList[index].index;
        data.index = oldIndex; // 改回自己原有的index
        state.msgList.splice(index, 1, data);
      }
    },
    msgList_add(state, data) {
      state.msgCount = data.index = state.msgCount + 1;
      state.msgList.unshift(data);
    },
    setTrigger(state, data) {
      state[data.triggerName] = data.value;
    },
    asideToggleWidth(state, flag) {
      state.aside_width = flag ? 60 : 200;
    },
    changeLogin(state, info) {
      state.auth_token = info.Authorization;
      state.username = info.username;
    },
    setFilterKey(state, option) {
      if (option === "none") {
        state.filterKey = null;
      } else {
        state.filterKey = option;
      }
    },
    showFloatWindow(state) {
      state.floatWindowFlag = true;
      // 等到onLoad触发以后在show float window 从而获取组件的时候传入的initX 是最新的windowWidth
    },
  },
  actions: {
    msgList_clean({ state, commit }) {
      state.msgList = [];
      state.msgCount = 0;
      commit("msgList_save");
    },
  },
  modules: {},
});
