<template>
  <div class="pc-wrapper">
    <app-header></app-header>
    <div class="main">
      <app-main></app-main>
    </div>
    <app-footer></app-footer>
  </div>
</template>

<script>
import AppHeader from './appHeader'
import AppFooter from './appFooter'
import AppMain from './appMain'

export default {
  name: 'LayoutView',
  components: {
    AppHeader,
    AppFooter,
    AppMain
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.pc-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
}
.main {
  // min-height: 800px;
  // position: fixed;
  // top: 72px;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // z-index: 1;
}
</style>
