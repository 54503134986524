<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<style lang="scss">
body,html,ul,li {
  margin: 0;
  padding: 0;
  list-style: none;

}
body,html {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  padding: 0;
  margin: 0;
}

nav {
  // padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

<style lang="scss">
::-webkit-scrollbar {
  display: none  !important;; /* Chrome Safari */
}
</style>

<script>
import { getNavigatorType } from '@/utils/equipment.js'
export default {
  name: 'app',
  components: {},
  watch: {
    $route: function (to, from) {
      // 判断设备情况，并根据设备切换路由
      // console.log(to, this.$pc)
      if (to.path.indexOf('_p') > 0 && this.$pc) {
        // 如果是去移动端，但是此时是pc端
        // console.log('pc端')
        // 手机端转电脑
        // 在路由尾巴添加_p变成电脑端
        this.$router.push({ path: to.path.split('_p')[0], replace: true })
        if (to.path === '/index_p') {
          const str = to.path.split('_p')[0]
          this.$router.push({ path: str, replace: true })
        }
      } else if (to.path.indexOf('_p') < 0 && !this.$pc) {
        // 电脑转手机端
        // 域名为index_p去掉_p变成手机端index路由
        this.$router.push({ path: to.path + '_p', replace: true })
        // console.log('移动端')
      }
    }
  },
  mounted () {
    // 根据系统来判断是否需要加padding
    // console.log(this.$pc)
    const navigatorType = getNavigatorType()
    // console.log(navigatorType)
    if (navigatorType === 'Android') {
      document.querySelector('#app').style.paddingTop = '20px'
    }
  }

}

</script>
